import {
  useCashSimpleSimulationStore,
  useMortgageSimpleSimulationStore
} from '~/models/simulation/simpleSimulation/SimpleSimulationInput';
import useSimpleSimulationApi from '~/service/api/useSimpleSimulationApi';
import {Ref} from 'vue';
import type {SimpleOffer} from '~/models/simulation/simpleSimulation/SimpleOffer';
import {ProductType, type SimulationProductType} from '~/enums/ProductType';

// Interface dla przyszłych useCashSimpleSimulation
// Content zawsze bedzie przyjmował taki interface i bedzie mozna robić display po wspólnym interface
export interface UseSimpleSimulation {
  error: any,
  pending: Ref<boolean>,
  offers: Ref<SimpleOffer[]>,
  fetchOffers: Function,
}

export async function useSimpleSimulation(type: SimulationProductType): Promise<UseSimpleSimulation> {
  const {fetchSimpleSimulation,} = useSimpleSimulationApi(type);

  const simulationStore = type === ProductType.CASH
      ? useCashSimpleSimulationStore()
      : useMortgageSimpleSimulationStore();

  const fetchFromStore = (type: ProductType) => {
    return fetchSimpleSimulation[type](simulationStore.simulationFormInput.value);
  };

  const {data: offers, pending, error,} = await fetchFromStore(type);

  const fetchOffers = useDebounceFn(async() => {
    pending.value = true;
    const {data,} = await fetchFromStore(type);
    offers.value = data.value;
    pending.value = false;
  }, 20);

  return {
    offers,
    pending,
    error,
    fetchOffers,
  };
}
